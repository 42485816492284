.header {
	z-index: 20;
	position: sticky;
	top: 0;
	padding-bottom: 1rem;
	background-color: white;
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.logo-header {
	width: 12rem;
	margin: 0.7rem;
}

.logo-header-mooviin {
	width: 240px;
	margin: 20px;
}

.back-button{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.logo-button-user{
	display: inline-flex;
    width: 50%;
    justify-content: space-between;
}

.logo-container {
	display: flex;
	justify-content: center;
	padding: 1rem;
}

.search-bar {
	width: 50%;
	padding-right: 4rem;
	height: 2.5rem;
	padding-left: 1.25rem;
	border: solid 1px #000;
	background-color: white;
	border-radius: 0.5rem;
}

.search-loading {
	width: 40px;
	margin-top: 50px;
}

.header-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
}

.results-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	width: 95%;
	margin-top: 1rem;
}

.show {
	display: inline-block;
}

.hide {
	display: none;
}

.search-icon {
	position: relative;
	left: -25px;
	color: gray;
}

.subtitle-tag {
	display: flex;
	justify-content: center;
}

.search-card-title {
	font-size: 1rem;
	font-weight: 700;
	margin-top: 10px;
	color: black;
}

.availability-date-container {
	width: 50%;
	margin: 0.75rem 0;
}

.stock-dispo-container {
	width: 40%;
	margin: 0.75rem 0;
}

.mini-card-stock {
	display: block;
	height: 3.5rem;
	padding: 0.25rem;
	border-radius: 0.375rem;
	--tw-bg-opacity: 1;
	background-color: #e0e0e0;
}

.mini-card-stock-title {
	font-size: 12px;
	font-weight: 600;
	width: 100%;
	color: #6f6f6f;
	padding-top: 5px;
}

.mini-card-stock-number {
	font-size: 1.2rem;
	font-weight: 800;
	color: #6f6f6f;
}

.mini-card-date {
	font-size: 1.2rem;
	font-weight: 800;
	color: #6f6f6f;
}

.big-card-bottom {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 0px;
}

.stock-tag {
	border-radius: 5px;
	color: white;
	font-size: 0.6rem;
	padding: 3px 8px;
	width: -moz-fit-content;
	width: fit-content;
}

.float-button {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 70px;
	right: 42%;
	background-color: #fdfdfd;
	color: #fff;
	border-radius: 50px;
	box-shadow: 2px 2px 3px #999;
}

/* CSS pour les etiquettes*/

.flash-tag {
	animation-duration: 0.8s;
	animation-name: flash;
	animation-iteration-count: infinite;
	transition: none;
}

@keyframes flash {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tag-container {
	margin-top: 0.5rem;
	justify-content: center;
	display: flex;
}

.tag-container-right {
	margin-top: 0.5rem;
	justify-content: flex-end;
	display: flex;
}

.arrival-button {
	background: none;
	border: 0;
	color: inherit;
	cursor: pointer;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	-webkit-user-select: none; /* for button */
	-webkit-appearance: button; /* for input */
	-moz-user-select: none;
	-ms-user-select: none;
}

.modal-card {
	display: flex;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 50;
	justify-content: center;
	align-items: center;
	outline: 0;
}

.info-arrivages-container {
	display: flex;
	justify-content: flex-end;
	padding: 5px 12px 0px;
	align-items: center;
}

.info-arrivage {
	font-size: 12px;
	font-weight: 700;
	color: #9097bd;
	padding-left: 5px;
}

.info-icon {
	font-size: 12px;
    color: #fff;
    margin-top: 4px;
    position: relative;
    right: -67px;
	bottom: -15px;
    z-index: 1;
}

.camera-button {
	font-size: 24px;
	color: #3a3a3a;
}

.container {
	text-align: center;
	display: grid;
	justify-items: center;
	padding-bottom: 40px;
	max-width: 100vw;
}

.products-number {
	margin-top: 15px;
	margin-bottom: -15px;
	font-weight: 600;
	color: darkgray;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(4, 25%);
	grid-template-rows: 1fr;
	gap: 10px 10px;
}

.text-arrivage {
	font-weight: 700;
}

@media mobile and (min-width: 450px) {
	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
		grid-template-rows: 1fr;
		gap: 10px 10px;
	}
}
