@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  height: -webkit-fill-available;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F6F1EC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  max-width: 100%;
  height: -webkit-fill-available;
}

.header {
	z-index: 20;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	padding-bottom: 1rem;
	background-color: white;
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000,
		0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.logo-header {
	width: 12rem;
	margin: 0.7rem;
}

.logo-header-mooviin {
	width: 240px;
	margin: 20px;
}

.back-button{
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.logo-button-user{
	display: inline-flex;
    width: 50%;
    justify-content: space-between;
}

.logo-container {
	display: flex;
	justify-content: center;
	padding: 1rem;
}

.search-bar {
	width: 50%;
	padding-right: 4rem;
	height: 2.5rem;
	padding-left: 1.25rem;
	border: solid 1px #000;
	background-color: white;
	border-radius: 0.5rem;
}

.search-loading {
	width: 40px;
	margin-top: 50px;
}

.header-container {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 2rem;
}

.results-container {
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
	width: 95%;
	margin-top: 1rem;
}

.show {
	display: inline-block;
}

.hide {
	display: none;
}

.search-icon {
	position: relative;
	left: -25px;
	color: gray;
}

.subtitle-tag {
	display: flex;
	justify-content: center;
}

.search-card-title {
	font-size: 1rem;
	font-weight: 700;
	margin-top: 10px;
	color: black;
}

.availability-date-container {
	width: 50%;
	margin: 0.75rem 0;
}

.stock-dispo-container {
	width: 40%;
	margin: 0.75rem 0;
}

.mini-card-stock {
	display: block;
	height: 3.5rem;
	padding: 0.25rem;
	border-radius: 0.375rem;
	--tw-bg-opacity: 1;
	background-color: #e0e0e0;
}

.mini-card-stock-title {
	font-size: 12px;
	font-weight: 600;
	width: 100%;
	color: #6f6f6f;
	padding-top: 5px;
}

.mini-card-stock-number {
	font-size: 1.2rem;
	font-weight: 800;
	color: #6f6f6f;
}

.mini-card-date {
	font-size: 1.2rem;
	font-weight: 800;
	color: #6f6f6f;
}

.big-card-bottom {
	display: flex;
	justify-content: space-evenly;
	margin-bottom: 0px;
}

.stock-tag {
	border-radius: 5px;
	color: white;
	font-size: 0.6rem;
	padding: 3px 8px;
	width: -moz-fit-content;
	width: -webkit-fit-content;
	width: fit-content;
}

.float-button {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 70px;
	right: 42%;
	background-color: #fdfdfd;
	color: #fff;
	border-radius: 50px;
	box-shadow: 2px 2px 3px #999;
}

/* CSS pour les etiquettes*/

.flash-tag {
	-webkit-animation-duration: 0.8s;
	        animation-duration: 0.8s;
	-webkit-animation-name: flash;
	        animation-name: flash;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	transition: none;
}

@-webkit-keyframes flash {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes flash {
	0% {
		opacity: 1;
	}
	40% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.tag-container {
	margin-top: 0.5rem;
	justify-content: center;
	display: flex;
}

.tag-container-right {
	margin-top: 0.5rem;
	justify-content: flex-end;
	display: flex;
}

.arrival-button {
	background: none;
	border: 0;
	color: inherit;
	cursor: pointer;
	font: inherit;
	line-height: normal;
	overflow: visible;
	padding: 0;
	-webkit-user-select: none; /* for button */
	-webkit-appearance: button; /* for input */
	-moz-user-select: none;
	-ms-user-select: none;
}

.modal-card {
	display: flex;
	overflow-y: auto;
	overflow-x: hidden;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 50;
	justify-content: center;
	align-items: center;
	outline: 0;
}

.info-arrivages-container {
	display: flex;
	justify-content: flex-end;
	padding: 5px 12px 0px;
	align-items: center;
}

.info-arrivage {
	font-size: 12px;
	font-weight: 700;
	color: #9097bd;
	padding-left: 5px;
}

.info-icon {
	font-size: 12px;
    color: #fff;
    margin-top: 4px;
    position: relative;
    right: -67px;
	bottom: -15px;
    z-index: 1;
}

.camera-button {
	font-size: 24px;
	color: #3a3a3a;
}

.container {
	text-align: center;
	display: grid;
	justify-items: center;
	padding-bottom: 40px;
	max-width: 100vw;
}

.products-number {
	margin-top: 15px;
	margin-bottom: -15px;
	font-weight: 600;
	color: darkgray;
}

.grid-container {
	display: grid;
	grid-template-columns: repeat(4, 25%);
	grid-template-rows: 1fr;
	grid-gap: 10px 10px;
	gap: 10px 10px;
}

.text-arrivage {
	font-weight: 700;
}

@media mobile and (min-width: 450px) {
	.grid-container {
		display: grid;
		grid-template-columns: repeat(4, 25%);
		grid-template-rows: 1fr;
		grid-gap: 10px 10px;
		gap: 10px 10px;
	}
}

.App {
	text-align: center;
}



.login {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

h4 {
	font-weight: bold;
	font-size: 0.8rem;
}

.subtitle {
	color: #ccc;
	margin-top: -5px;
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 0.8rem;
}

.clients-screen {
	max-width: 100vw;
    display: flex;
    justify-content: center;
}


.client-card {
    display: flex;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem;
    background-color: #fff;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7rem;
}

.modal-background {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	width: auto;
	max-width: 48rem;
}

.modal {
	display: flex;
	position: relative;
	background-color: #ffffff;
	flex-direction: column;
	width: 100%;
	border-radius: 0.5rem;
	border-width: 0;
	outline: 0;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.modal-title-container {
	display: flex;
	padding: 1.25rem 1rem 0rem 1rem;
	justify-content: space-between;
	align-items: flex-start;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.modal-black-background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 40;
	background-color: #000000;
	opacity: 0.25;
}

.arrow-left {
	position: relative;
	width: 0;
	height: 0;
	border-bottom: 40px solid #9097bd;
	border-left: 40px solid transparent;
	right: -34px;
	bottom: -11px;
}

.modal-content {
	position: relative;
	padding: 0rem 0.75rem 0.75rem;
	flex: 1 1 auto;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.pre-login-card {
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: 0 0 #0000, 0 0 #0000, var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-button-connexion {
	padding-right: 13px;
}

.button-div{
	display: inline-block;
}

.text-connection {
	font-size: 0.7rem;
    font-weight: bold;
}

.auth0-button {
	display: flex;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    border: 1px solid #eee;
    border-radius: 10px;
    align-items: center;
}

.auth0-button:hover {
	background-color: #eee;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		-webkit-animation: App-logo-spin infinite 20s linear;
		        animation: App-logo-spin infinite 20s linear;
	}
}

.arrival-card {
	display: flex;
	padding: 0rem 1rem;
    margin: 0.3rem 0rem;
	background-color: #d1d5db;
	justify-content: space-evenly;
	border-radius: 0.25rem;
}

.arrival-card-element{
	margin: 0.3rem;
}

.modal-title {
	font-size: 1rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.product-stock-card {
	overflow: hidden;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	background-color: #ffffff;
	border-radius: 0.7rem;
	padding: 10px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

@keyframes App-logo-spin {
	from {
		-webkit-transform: rotate(0deg);
		        transform: rotate(0deg);
	}
	to {
		-webkit-transform: rotate(360deg);
		        transform: rotate(360deg);
	}
}

.footer {
	position: fixed;
	top: auto;
	bottom: 0;
	width: 100%;
	height: 40px;
	padding-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.9);
	color: rgb(92, 92, 92);
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: 0 0 #0000,
		0 0 #0000, var(--tw-shadow);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text404 {
	margin-top: -7vw;
	font-size: 24vw;
	color: white;
	font-weight: 900;
	position: relative;
}

.page404 {
	margin-top: 2vw;
	font-size: 4vw;
	color: white;
	font-weight: 900;
	position: relative;
}

.globe {
	display: flex;
	align-content: center;
	justify-content: center;
}

